let header = document.querySelector('#header');
let body = document.querySelector('body');
let start = window.scrollY; // Currenty starting scroll position
let difference = 250; // Scroll up x pixels before hiding
let foldHeight = (window.outerHeight); // Start sticky after 1 full window height
let lastDown = 0; // Check for upbased on this

if(header !== null) {
  ['scroll', 'onload'].forEach((event) => {
    window.addEventListener(event, (e) => {
      if(header.style.opacity == 0) header.style.opacity = 100;

      if(window.scrollY > foldHeight) {
        if(header.style.marginTop !== '0px')
          header.style.marginTop = '0px';
      } else {
        header.style.marginTop = null;
      }
    });
  })
}
